<template>
  <div>
  <v-btn
    class="code-coppy-btn"
    :text-to-copy="textToCopy"
    @click="copyToClipboard"
  >
    <v-icon>mdi-content-copy</v-icon>
    <span>{{ copyText }}</span>
  </v-btn>
</div>
</template>

<script>
export default {
  name: "CopyToClipBoard",
  props: {
    textToCopy: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    text: "Copy to clipboard",
    copyText: "",
  }),

  created() {
    this.copyText = this.text;
  },

  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.textToCopy);
      this.copyText = "Copied";
        setTimeout(() => {
            this.copyText = this.text;
        }, 1000);
    },

    reset() {
      this.copyText = this.text;
    },
  },
};
</script>


<style scoped>

div {
  position: absolute;
    right: 0%;
    padding: 10px 30px 0px 0px;
}

span {
  font-size: 10px;
    font-family: 'Roboto';
}


.v-btn:not(.v-btn--round).v-size--default {
  min-width: 30px !important;
  height: 30px !important;
  padding: 0 5px !important;
}

</style>