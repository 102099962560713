<template>
  <v-card rounded="lg">
    <v-list v-if="leftMenuItems">
      <div
        v-for="(leftMenu, index) in leftMenuItems"
        :key="index"
        :prepend-icon="leftMenu.icon"
      >
        <v-list-item v-if="!leftMenu.items" :to="{name:leftMenu.route.name}">
          <v-list-item-title> {{ leftMenu.title }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-else>
          <template v-slot:activator>
            <v-list-item-title> {{ leftMenu.title }}</v-list-item-title>
          </template>

          <template v-if="leftMenu.items">
            <div v-for="(item, index) in leftMenu.items" :key="index">
              <v-list-item v-if="!item.subItems" :to="{name:item.route.name}">
                <v-list-item-title> {{ item.title }}</v-list-item-title>
              </v-list-item>

              <v-list-group no-action sub-group link v-else>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-if="item.subItems">
                  <v-list-item
                    v-for="(subItem, index) in item.subItems"
                    :key="index"
                    :to="{name:subItem.route.name}"
                  >
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list-group>
            </div>
          </template>
        </v-list-group>
      </div>
    </v-list>
  </v-card>
</template>

<script>
export default {
  components: {
    //
  },
  computed: {
    //
  },
  props: {
    leftMenuItems: {
      type: Array,
    },
  },
  data: () => ({
    //
  }),
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>