export default [
  {
    title: "What is Tabulator ?",
    route: { name: "PageDeveloperComponentBreadTabulator" },
  },
  {
    title: "Component",
    route: { name: "PageDeveloperComponentBreadTabulatorComponent" },
  },

  {
    title: "Table",
    route: { name: "PageDeveloperComponentBreadTabulatorTable" },
  },

  {
    title: "Filter",
    route: { name: "PageDeveloperComponentBreadTabulatorFilter" },
  },

  {
    title: "Columns",
    route: { name: "PageDeveloperComponentBreadTabulatorColumns" },
  },

  {
    title: "Edit Cell",
    route: { name: "PageDeveloperComponentBreadTabulatorEditCell" },
  },

  {
    title: "Export",
    route: { name: "PageDeveloperComponentBreadTabulatorExport" },
  },
];
